@tailwind base;
@tailwind components;
@tailwind utilities;

/* 1. Typography */
body {
    @apply font-lla;
}

/* 2. Components */
/* 2.1 User Navbar and user dropdown */

.disabledLink {
    pointer-events: none;
}

.userIcon {
    /*@apply hover:text-lla-main dark:text-lla-dark-text transition-colors ease-in hover:cursor-pointer;*/
    @apply hover:text-lla-main dark:text-lla-dark-text transition-colors ease-in;
}

.dropdown-wrapper[aria-expanded="true"] {
    @apply text-lla-main dark:text-lla-dark-text;
}

.dropdown-wrapper[aria-expanded="true"] .arrow-icon {
    @apply rotate-180 transform-gpu ease-in-out transition-transform;
}

/* 2.2 Incidents and Status Page */
.incident-wrapper {
    @apply rounded-md p-3 bg-gray-100;
}

/* 2.3 Buttons */

.solid-button {
    @apply px-4 rounded-md uppercase py-2 bg-lla-main text-white hover:bg-opacity-95 transition-all ease-in-out;
}

.secondary-button {
    @apply px-4 rounded-md uppercase py-2 bg-lla-copper-500 text-white hover:bg-opacity-95 transition-all ease-in-out;
}

.ghost-button {
    @apply px-4 rounded-md uppercase py-2 transition-all ease-in-out text-lla-main dark:text-lla-dark-text hover:bg-lla-main hover:bg-opacity-5;
}

.markdown-content ul li:not(:first-child), .markdown-content ol li:not(:first-child) {
    margin-top: 5px;
}

.markdown-content ul li {
    list-style-type: disc;
    margin-left: 15px;
}

.markdown-content p:not(:last-child) {
    margin-bottom: 6px;
}

.markdown-content h1 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 6px;
}

.changelog pre {
    margin: 0 0 10px 15px;
}

.markdown-content h1:not(:first-child) {
    margin-top: 20px;
}

.markdown-content strong {
    font-weight: bold;
}

.markdown-content h2 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
}

.markdown-content h3 {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
    font-weight: bold;
}

.markdown-content ul {
    margin-top: 10px;
    margin-bottom: 10px;
}

.infobox p:not(:first-child) {
    margin-top: 5px;
}

.markdown-content a {
    text-decoration: underline;
}

.markdown-content ol li {
    margin-left: 15px;
    list-style-type: number;
}

canvas {
    margin: 25px 0 25px 0;
    display: inline;
}

input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #48bb78;
}

#svg-container {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 45px;
    max-width: 45px;
    margin: auto;
}

#svg-1 {
    margin: 0 auto;
    display: block;
}

pre {
    /*outline: 1px solid #ccc;*/
    padding: 5px;
    margin: 15px;
    font-size: 14px;
}

h1 {
    font-weight: bold;
    font-size: 2.15rem;
}

h2 {
    font-weight: bold;
    font-size: 1.5rem;
}

h3 {
    font-weight: bold;
    font-size: 1.3rem;
}

/* since nested groups are not supported we have to use
   regular css for the nested dropdowns
*/
li>ul                 { transform: translatex(100%) scale(0) }
li:hover>ul           { transform: translatex(101%) scale(1) }
li > button svg       { transform: rotate(-90deg) }
li:hover > button svg { transform: rotate(-270deg) }

/* Below styles fake what can be achieved with the tailwind config
   you need to add the group-hover variant to scale and define your custom
   min width style.
   See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
   for implementation with config file
*/
.group:hover .group-hover\:scale-100 { transform: scale(1) }
.group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
.scale-0 { transform: scale(0) }
.min-w-32 { min-width: 8rem }
